@font-face {
  font-family: "DxGrafik";
  src: url("./fonts/DxGrafik-BlackItalic.otf") format("opentype");
}

@font-face {
  font-family: "DxGrafik Semi";
  src: url("./fonts/DxGrafik-SemiBold.otf") format("opentype");
}

:root {
  margin: 0;
  padding: 0;
  overflow-y: hidden;
}

body {
  font-family: "DxGrafik Semi", -apple-system, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
}

canvas {
  pointer-events: none;
}

.name-container {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.name-container h1 {
  font-size: 10vw;
  text-align: center;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-image: linear-gradient(342deg, #ffffff37, #ffffff31);
}

.links-container {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  width: 100%;
  height: 100%;
  margin-top: 6vw;
  gap: 20px;
}

.links-container a {
  font-size: 3vw;
  text-align: center;
  text-decoration: none;
  pointer-events: all;
  color: #ffffff37;
}

.links-container a:hover {
  color: #d17474;
}
